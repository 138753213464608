import React from "react"
import Layout from "../components/shared/layout"
import { WebsiteTermsofUseComponent } from "../components/terms-and-conditions/website-terms-of-use.component"

import CookiePolicyComponent from "../components/terms-and-conditions/cookie-policy.component"

import PrivacyPolicyComponent from "../components/terms-and-conditions/privacy-policy.component"

const TosPage = () => (
  <div className="tnc-page">
    <Layout header={true} footer={true}>
      <WebsiteTermsofUseComponent />
      <CookiePolicyComponent />
      <PrivacyPolicyComponent />
    </Layout>
  </div>
)

export default TosPage
