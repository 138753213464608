import React from "react"
import { Link } from "gatsby"

export const WebsiteTermsofUseComponent = () => {
  const links = {
    "cookie-policy": "#cookie-policy",
    "privacy-policy": "#privacy-policy",
    imprint: "/disclaimer",
    "contact-mail": "kontakt@bolttech.at",
  }
  return (
    <div className="tnc-section">
      <div className="container">
        <h3 className="bold title">Website-Nutzungsbedingungen</h3>
        <div className="tnc-wrapper">
          <p>
            <strong>direkt-schutz.bolttech.at ("Website") </strong>
            ist  Eigentum  der  Bolttech  Device  Protection (AUT) GmbH ("
            <strong>Bolttech</strong>").
          </p>

          <p>
          Die  Inhalte  auf  dieser  Website  werden  von  der  Bolttech  Device  Protection  (AUT) GmbH ("<strong>Bolttech</strong>")
            bereitgestellt.
          </p>

          <h3 className="bold">Allgemein</h3>
          <p>
            Dies sind die allgemeinen Geschäftsbedingungen ("
            <strong>Website-Bedingungen</strong>"), die für Ihre Nutzung dieser
            Website gelten. Bitte lesen Sie sie sorgfältig durch und wenn Sie
            Fragen oder Probleme haben, kontaktieren Sie uns bitte, indem Sie
            sich mit uns in Verbindung setzen.
          </p>
          <p>
            Indem Sie mit dem Zugriff auf diese Website fortfahren, wird davon
            ausgegangen, dass Sie unsere Website-Bedingungen sowie unsere
            <Link to={links["cookie-policy"]}>Cookie-Richtlinie</Link>
            und
            <Link to={links["privacy-policy"]}>Datenschutzrichtlinie</Link>, die
            unten aufgeführt sind und die unsere Website-Bedingungen ergänzen,
            akzeptieren.
          </p>
          <p>
            Bitte beachten Sie, dass diese Website nur für die Nutzung durch
            Personen mit Wohnsitz in Österreich bestimmt ist, solange sie sich
            in Österreich aufhalten. Um Zweifel auszuschließen, stellt keine der
            Informationen auf dieser Website ein Vertragsangebot in einem Land
            dar, in dem sie verfügbar ist.
          </p>

          <p>
            Diese Website-Bedingungen gelten zusätzlich zu den Bedingungen, die
            Sie möglicherweise als direkter Kunde haben ("
            <strong>Direkte Bedingungen</strong>"), und im Falle eines Konflikts
            zwischen diesen Website-Bedingungen und den direkten Bedingungen
            haben die Bedingungen der direkten Bedingungen Vorrang.
          </p>
          <p>
            Für alle erworbenen Produkte oder Dienstleistungen gelten eigene
            Geschäftsbedingungen des jeweiligen Anbieters, die Sie ebenfalls
            lesen müssen.
          </p>
          <h3 className="bold">Links zu anderen Websites</h3>
          <p>
            Diese Website kann auch Hypertext-Links oder andere Links zu bzw.
            Informationen von Websites enthalten, die von Dritten (d.h. anderen
            Personen oder Unternehmen als Bolttech) betrieben werden. Wo die
            Website solche Informationen und/oder Links enthält, dienen diese
            nur zu Informationszwecken und zu Ihrer Bequemlichkeit, und Bolttech
            übernimmt keinerlei Verantwortung für solche Hyperlinks, Links,
            Websites oder Inhalte. Für solche Websites können auch gesonderte
            Bedingungen gelten. Bolttech übernimmt keine Verantwortung für den
            Inhalt oder Ihre Nutzung von Websites, die über einen Hypertext-Link
            oder einen anderen Link von dieser Website aus zugänglich sind.
          </p>
          <h3 className="bold">Verfügbarkeit und Zugang</h3>
          <p>
            Bolttech garantiert nicht, dass diese Website frei von Viren,
            Würmern, trojanischen Pferden oder technischen Problemen ist, die
            durch die Nutzung dieser Website entstehen. Bolttech wird sich
            bemühen, dass diese Website 24 Stunden am Tag zugänglich ist, haftet
            jedoch nicht, wenn die Website aus irgendeinem Grund für eine
            bestimmte Zeit oder für einen bestimmten Zeitraum nicht verfügbar
            ist.
          </p>

          <p>
            Bolttech hat das Recht, den Zugang zur Website (oder zu Teilen
            davon) vorübergehend oder dauerhaft und ohne Vorankündigung
            auszusetzen.
          </p>

          <p>
            Der Zugang zu und die Nutzung von bestimmten Teilen dieser Website
            kann jederzeit auf registrierte Benutzer beschränkt sein, für die
            weitere Bedingungen für den Zugang gelten können. Gegebenenfalls
            werden Sie während des Registrierungsvorgangs auf diese Bedingungen
            hingewiesen. Bolttech kann nach eigenem Ermessen die Registrierung
            eines Nutzers oder den Zugang zu bestimmten Teilen der Website für
            bestimmte Nutzer verweigern, ohne dafür Gründe anzugeben.{" "}
          </p>
          <h3 className="bold">Änderungen </h3>

          <p>
            Bolttech behält sich das Recht vor, Ergänzungen und Änderungen an
            den Informationen auf der Website vorzunehmen, und alle Hinweise auf
            Details von Produkten oder Dienstleistungen, einschließlich der
            Preise, können ohne vorherige Ankündigung geändert werden.
          </p>
          <h3 className="bold">Variation</h3>
          <p>
            Bolttech behält sich das Recht vor, von Zeit zu Zeit Ergänzungen und
            Änderungen an diesen Website-Bedingungen vorzunehmen, ohne Sie davon
            in Kenntnis zu setzen, um Änderungen der Marktbedingungen, die sich
            auf das Geschäft von Bolttech auswirken, Änderungen der Technologie,
            Änderungen der Zahlungsmethoden, Änderungen der relevanten Gesetze
            und regulatorischen Anforderungen sowie sonstige Änderungen zu
            berücksichtigen.
          </p>

          <p>
            Eine solche Änderung wird wirksam, sobald die überarbeiteten
            Website-Bedingungen auf der Website veröffentlicht wurden. Sie sind
            dafür verantwortlich, die Website-Bedingungen jedes Mal zu
            überprüfen, wenn Sie die Website nutzen, und wir werden Ihre Nutzung
            der Website so behandeln, als würden Sie die Website-Bedingungen
            akzeptieren, die zum Zeitpunkt Ihres Zugriffs auf die Website
            gelten.
          </p>
          <h3 className="bold">Sicherheit</h3>
          <p>
            Bolttech wird alle angemessenen Maßnahmen ergreifen, um
            sicherzustellen, dass alle Informationen, die Sie (per E-Mail oder
            anderweitig) als Ergebnis Ihres Zugriffs auf diese Website zur
            Verfügung stellen, sicher aufbewahrt werden, aber bitte denken Sie
            daran, dass aufgrund der Natur des Internets die Sicherheit von
            E-Mails nicht vollständig garantiert werden kann. Bitte beachten Sie
            auch unsere Datenschutzrichtlinie, die unten aufgeführt ist.
          </p>
          <h3 className="bold">Haftung und Ihre Nutzung der Website</h3>
          <p>
            Alle Garantien, Bedingungen, Zusicherungen und Bestimmungen, ob
            ausdrücklich oder stillschweigend durch Gesetz, Gewohnheitsrecht
            oder anderweitig, in Bezug auf diese Website und ihren Inhalt,
            einschließlich, aber nicht beschränkt auf stillschweigende Garantien
            der zufriedenstellenden Qualität, der Eignung für einen bestimmten
            Zweck, der Nichtverletzung von Rechten, der Kompatibilität, der
            Sicherheit und der Genauigkeit, sind von diesen Website-Bedingungen
            ausgeschlossen, soweit sie von Rechts wegen ausgeschlossen werden
            können. Darüber hinaus garantiert Bolttech nicht, dass die Website
            ununterbrochen oder fehlerfrei zur Verfügung steht oder dass etwaige
            Mängel behoben werden.
          </p>

          <p>
            Soweit gesetzlich zulässig, haftet Bolttech nicht (weder vertraglich
            noch aus unerlaubter Handlung, einschließlich Fahrlässigkeit oder
            Pflichtverletzung, oder anderweitig) für Schäden, Verluste oder
            Haftungen, die sich aus oder in Verbindung mit Ihrer Nutzung dieser
            Website ergeben.
          </p>
          <p>
            Ihr Vertrauen auf die auf dieser Website enthaltenen Informationen
            oder Ihre Unfähigkeit, diese zu nutzen; oder jegliches Versagen oder
            Leistung, Fehler, Auslassungen, Unterbrechungen, Defekte,
            Verzögerungen beim Betrieb oder bei der Übertragung, Computerviren,
            Würmer, trojanische Pferde oder Systemausfälle, Nichtverfügbarkeit
            oder Aussetzung dieser Website, einschließlich, aber nicht
            beschränkt auf Gewinnverluste, Einkommensverluste, Verlust
            erwarteter Einsparungen, Einnahmeverluste, Datenverluste, Verlust
            des Firmenwerts oder Verlust von Verträgen oder Geschäften (in jedem
            Fall, ob direkt oder indirekt) noch für jegliche indirekten,
            wirtschaftlichen, Folge- oder Sonderverluste, die aus jeglicher
            Ursache resultieren.
          </p>

          <p>
            Soweit nach geltendem Recht zulässig, lehnt Bolttech ausdrücklich
            jegliche Haftung, ob aus Vertrag, unerlaubter Handlung (oder
            Arglist) oder anderweitig (einschließlich, aber nicht beschränkt auf
            die Haftung für fahrlässige Handlungen oder Unterlassungen),
            gegenüber jedermann in Bezug auf Ansprüche oder Verluste jeglicher
            Art ab, die sich direkt oder indirekt ergeben aus: (i) einer
            Handlung oder den Folgen einer Handlung oder Unterlassung, die ganz
            oder teilweise im Vertrauen auf die Gesamtheit oder einen Teil der
            Informationen dieser Website vorgenommen wurde; und (ii) der
            Verwendung von Daten oder Materialien auf dieser Website oder dem
            unbefugten Zugriff auf diese Website oder anderweitig.
          </p>

          <p>
            Bolttech haftet nicht für Verstöße gegen diese Website-Bedingungen,
            die durch Umstände außerhalb der Kontrolle von Bolttech verursacht
            werden.
          </p>
          <p>
            Nichts in diesen Bedingungen schließt die Haftung von Bolttech für
            Todesfälle oder Personenschäden aus, die auf Fahrlässigkeit von
            Bolttech zurückzuführen sind. Sie haften für und halten Bolttech
            und/oder gegebenenfalls die Tochtergesellschaften, leitenden
            Angestellten, Direktoren, Vertreter, Lizenzgeber, Nachfolger und
            Zessionare von Bolttech schadlos gegenüber allen Ansprüchen, Klagen,
            Verbindlichkeiten, Verlusten, Schäden und Ausgaben (einschließlich
            Rechtskosten) jeglicher Art, die sich direkt oder indirekt aus einer
            Verletzung dieser Website-Bedingungen durch Sie ergeben.
          </p>
          <h3 className="bold">
            Urheberrecht und andere geistige Eigentumsrechte
          </h3>

          <p>
            Bolttech (oder seine Tochtergesellschaften oder Lizenzgeber) sind
            Eigentümer des Urheberrechts und aller geistigen Eigentumsrechte,
            die auf oder innerhalb dieser Website bestehen (einschließlich, aber
            nicht beschränkt auf alle Datenbankrechte, Marken, eingetragene und
            nicht eingetragene Marken, Dienstleistungsmarken und Logos). Durch
            die Website wird niemandem eine Lizenz oder ein Recht in Bezug auf
            dieses geistige Eigentum übertragen. Der Name Bolttech darf ohne
            unsere vorherige schriftliche Zustimmung in keiner Weise verwendet
            werden, auch nicht in der Werbung oder Öffentlichkeitsarbeit im
            Zusammenhang mit der Verbreitung von Informationen.
          </p>
          <p>
            Die Bilder, Logos und Namen auf der Website, die Bolttech oder einen
            Anbieter von Produkten oder Dienstleistungen von Zeit zu Zeit
            identifizieren, sind geschützte Marken von Bolttech oder dem
            betreffenden Anbieter. Nichts auf der Website überträgt irgendjemandem eine Lizenz oder ein Recht in Bezug auf ein solches Bild, Logo oder
            einen solchen Namen.
          </p>

          <p>
            Es ist Ihnen nicht gestattet, Informationen von dieser Website
            herunterzuladen, auszudrucken, weiterzugeben oder zu extrahieren,
            außer für Ihren persönlichen, nicht-kommerziellen Gebrauch.
          </p>

          <p>
            Es ist Ihnen nicht gestattet, die auf dieser Website verfügbaren
            Informationen, mit Ausnahme Ihrer persönlichen Daten, zu
            reproduzieren, zu kopieren, weiterzugeben, zu modifizieren oder in
            irgendeiner Weise zu verändern.
          </p>
          <p>
            Sie verpflichten sich, Bolttech, seine leitenden Angestellten,
            Direktoren, Agenten, Lizenzgeber, Nachfolger und Zessionare von
            allen Verbindlichkeiten, Ansprüchen, Verlusten, Kosten, Schäden und
            Ausgaben, einschließlich Anwaltskosten, die Bolttech entstehen,
            freizustellen und schadlos zu halten, wenn Sie gegen eine der
            Bestimmungen in diesem Abschnitt verstoßen.
          </p>
          <h3 className="bold">Kommunikation</h3>
          <p>
            Anwendbare Gesetze können verlangen, dass bestimmte Informationen
            oder Kommunikationen in schriftlicher Form erfolgen. Wenn Sie die
            Website nutzen, akzeptieren Sie und stimmen zu, dass die
            Kommunikation mit Bolttech elektronisch erfolgen kann. Bolttech kann
            Sie per E-Mail kontaktieren oder Ihnen Informationen durch die
            Veröffentlichung von Hinweisen auf dieser Website zur Verfügung
            stellen.
          </p>
          <p>
            Für vertragliche Zwecke erklären Sie sich mit elektronischen
            Kommunikationsmitteln einverstanden und erkennen an, dass alle
            Verträge, Mitteilungen, Informationen und andere Mitteilungen, die
            Bolttech Ihnen elektronisch zur Verfügung stellt, den gesetzlichen
            Anforderungen an die Schriftform entsprechen. Diese Bestimmung
            beeinträchtigt nicht Ihre gesetzlichen Rechte.
          </p>
          <h3 className="bold">Trennbarkeit</h3>
          <p>
            Sollte eine dieser Bedingungen aufgrund der Gesetze eines Staates
            oder Landes, in dem diese Bedingungen wirksam sein sollen,
            rechtswidrig, ungültig oder anderweitig nicht durchsetzbar sein, so
            werden diese Bedingungen im Umfang der Rechtswidrigkeit,
            Ungültigkeit oder Nichtdurchsetzbarkeit nur in Bezug auf diesen
            Staat oder dieses Land gestrichen und von diesen Bedingungen
            abgetrennt, und die verbleibenden Bedingungen bleiben in vollem
            Umfang gültig und wirksam.
          </p>
          <h3 className="bold">Verzicht</h3>
          <p>
            Sollte Bolttech es zu irgendeinem Zeitpunkt unterlassen, auf der
            strikten Erfüllung einer Ihrer Verpflichtungen aus diesen
            Website-Nutzungsbedingungen zu bestehen, oder sollte Bolttech es
            unterlassen, eines seiner Rechte oder Rechtsmittel auszuüben, zu
            denen Bolttech gemäß diesen Website-Nutzungsbedingungen berechtigt
            ist, so stellt dies keinen Verzicht auf diese Rechte oder
            Rechtsmittel dar und entbindet Sie nicht von der Einhaltung dieser Verpflichtungen.
          </p>
          <h3 className="bold">Gesamte Vereinbarung </h3>
          <p>
            Diese Website-Bedingungen stellen die gesamte Vereinbarung von
            Bolttech in Bezug auf Ihre Nutzung dieser Website dar. Nichts, was
            von einer Verkaufsperson im Namen von Bolttech gesagt wird, sollte
            als eine Änderung dieser Website-Bedingungen verstanden werden.
          </p>
          <h3 className="bold">Anwendbares Recht </h3>
          <p>
            Bolttech hat diese Website in Österreich eingerichtet. Die Nutzung
            unterliegt zu jeder Zeit österreichischem Recht und im Falle eines
            Rechtsstreits unterwerfen sich die Parteien unwiderruflich der
            ausschließlichen Zuständigkeit der österreichischen Gerichte.
          </p>
          <h3 className="bold">Kontaktaufnahme mit uns </h3>
          <p>
            Fragen, Kommentare und Wünsche sind willkommen, wenn Sie sich mit
            uns in Verbindung setzen.
          </p>
          <h3 className="bold">Reklamationen </h3>
          <p>
            Wir wissen, dass manchmal Dinge schief gehen, und für den
            unwahrscheinlichen Fall, dass dies geschieht, wollen wir es richtig
            stellen. Als geschätzter Kunde von Bolttech möchten wir, dass Sie
            uns mitteilen, wenn unsere Beratung oder unser Service nicht Ihren
            Erwartungen entspricht. Wir nehmen jede Kundenunzufriedenheit ernst
            und versuchen, die daraus gewonnenen Erkenntnisse zu nutzen, um
            unsere Arbeitsweise zu verbessern. Bitte kontaktieren Sie uns, wenn
            Sie eine Beschwerde einreichen möchten.
          </p>
          <h3 className="bold">Unsere Kontaktdaten </h3>
          <p>
            <strong>Abteilung Kundenservice</strong>
            <br />
            Bolttech Geräteschutz (AUT) GmbH,<br />
            Kohlmarkt 8-10, <br /> 1010 Wien,
            <br />
            <br />
            oder den Kundendienst unter: <br />
            <br />
            E-Mail:{" "}
            <a href={"mailto:" + links["contact-mail"]}>kontakt@bolttech.at</a>
            <br />
            Telefon: +43 1 206092917
          </p>
          <br />
          <strong>Zuletzt aktualisiert: 23.12.2020 </strong>
        </div>
      </div>
    </div>
  )
}
